import { html, LitElement } from "lit"
import { customElement, property, state } from "lit/decorators.js"
import { ifDefined } from "lit/directives/if-defined.js"
import { unsafeHTML } from "lit/directives/unsafe-html.js"
import { cond } from "../common/directives/cond.js"
import { IconManager, IconResolver } from "./IconManager.js"

import componentStyle from "../common/styles/Component.css"
import style from "./Icon.css"
import { observe } from "../common/decorators/observe.js"

/**
 * Icons are used to provide additional meaning or in places where text label doesn’t fit.
 * Icon component allows you to display an icon from the Nordicons library.
 *
 * @status ready
 * @category image
 * @slot - The default slot used for placing a custom SVG icon.
 */
@customElement("nord-icon")
export default class Icon extends LitElement {
  static styles = [componentStyle, style]

  private static manager = new IconManager()

  /**
   * Register a custom icon resolver, which accepts the icon name as an parameter, and returns an SVG string.
   * Can return a string synchronously, or a promise of a string.
   * By default, will load icons from the Nord CDN.
   * @param resolver The resolver function to register.
   */
  static registerResolver(resolver: IconResolver) {
    this.manager.resolver = resolver
  }

  /**
   * Register an individual icon so it can be rendered synchronously, to avoid loading over the network.
   * @param icon An object representing the icon to be registered, where "title" is the icon's name, and "default" is the SVG string.
   * This is intended to be used in cases where you import an icon's entire ES module and register it directly.
   */
  static registerIcon(icon: { title: string; default: string }): void

  /**
   * Register an individual icon so it can be rendered synchronously, to avoid loading over the network.
   * @param name The name of the icon to be registered.
   * @param icon The SVG string for the icon.
   */
  static registerIcon(name: string, icon: string): void

  /**
   * Register an individual icon so it can be rendered synchronously, to avoid loading over the network.
   * @param iconOrName The name of the icon to be registered or an object representing the icon to be registered, where "title" is the icon's name, and "default" is the SVG string.
   * @param icon The SVG string for the icon.
   */
  static registerIcon(iconOrName: string | { title: string; default: string }, icon?: string) {
    return this.manager.registerIcon(iconOrName, icon)
  }

  /**
   * The name of the icon to display, as defined by [nordicons](/nordicons/).
   */
  @property({ reflect: true }) name: string = ""

  /**
   * The size of the icon.
   */
  @property({ reflect: true }) size?: "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl"

  /**
   * The color of the icon.
   * Can accept any valid CSS color value, including custom properties.
   */
  @property({ reflect: true }) color?: string

  /**
   * An accessible label for the icon.
   * If no label is supplied, the icon is hidden from assistive technology.
   */
  @property({ reflect: true }) label?: string

  @state() private svg: string = ""

  render() {
    // if a label is supplied, we give the div a role of img.
    // without this we could not use aria-label, since it is only valid on elements of certain roles.
    // we always hide the inner svg, since the svg does not have any text/title/label itself.
    return html`
      <div
        role=${cond(this.label, "img")}
        style=${cond(this.color, `color:${this.color}`)}
        aria-label=${ifDefined(this.label)}
      >
        <slot aria-hidden="true"></slot>
        <div aria-hidden="true">${unsafeHTML(this.svg)}</div>
      </div>
    `
  }

  @observe("name")
  protected handleNameChange() {
    if (!this.name) {
      this.svg = ""
      return
    }

    Icon.manager.resolve(this.name, svg => {
      this.svg = svg
    })
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "nord-icon": Icon
  }
}
